<template>
  <article>
    <rtg-header />
    <div id="fullpage">
      <section class="section section--top">
        <rtg-sec-top />
      </section>
      <section class="section section--concept">
        <div class="fp-bg" />
        <rtg-sec-concept />
      </section>
      <section class="section section--recruit">
        <div class="fp-bg" />
        <rtg-sec-recruit />
      </section>
      <section class="section section--feature">
        <div class="fp-bg" />
        <rtg-sec-feature />
      </section>
      <section class="section section--company">
        <div class="fp-bg" />
        <rtg-sec-company />
      </section>
      <section class="section section--partner">
        <div class="fp-bg" />
        <rtg-sec-partner />
      </section>
    </div>
  </article>
</template>

<script>
import RtgHeader from './components/RtgHeader.vue'
import RtgSecTop from './components/RtgSecTop.vue'
import RtgSecConcept from './components/RtgSecConcept.vue'
import RtgSecRecruit from './components/RtgSecRecruit.vue'
import RtgSecFeature from './components/RtgSecFeature.vue'
import RtgSecCompany from './components/RtgSecCompany.vue'
import RtgSecPartner from './components/RtgSecPartner.vue'

export default {
  name: 'App',
  components: {
    RtgHeader,
    RtgSecTop,
    RtgSecConcept,
    RtgSecRecruit,
    RtgSecFeature,
    RtgSecCompany,
    RtgSecPartner
  }
}
</script>

<style scoped lang="scss">

.section {
  box-sizing: border-box;
  position: relative;
  z-index: 0;

  &--concept {
    background-color: $rtgBlue;
    .fp-bg { background: url(~@/assets/images/bg1.svg), url(~@/assets/images/bg1.png); }
  }

  &--recruit {
    background-color: $rtgOrange;
    .fp-bg { background: url(~@/assets/images/bg2.svg), url(~@/assets/images/bg2.png); }
  }

  &--feature {
    background-color: $rtgPurple;
    .fp-bg { background: url(~@/assets/images/bg3.svg), url(~@/assets/images/bg3.png); }
  }

  &--partner {
    background-color: $rtgRed;
    .fp-bg { background: url(~@/assets/images/bg4.svg), url(~@/assets/images/bg4.png); }
  }

  &--company {
    background-color: $rtgGreen;
    .fp-bg { background: url(~@/assets/images/bg5.svg), url(~@/assets/images/bg5.png); }
  }

  .fp-bg {
    background-position: bottom right, bottom center;
    background-size: 60%, 100%;
    background-repeat: no-repeat, no-repeat;
  }
}

@include mq-down(lg) {
  .section {
    .fp-bg {
      background-position: 100% 100%, bottom center;
      background-size: 70%, 200%;
    }
  }
}

@include mq-down(lg-h) {
  .section {
    .fp-bg {
      background-position: 100% 100%, bottom center;
      background-size: 40%, 200%;
    }
  }
}

@include mq-down(md) {
  .section {
    .fp-bg {
      background-position: 0% 100%, bottom center;
      background-size: 100%, 400%;
    }
  }
}

</style>
